<template>
  <div class="transaction-panel">
    <div class="layout-left d-flex align-items-start flex-column">
      <div class="detail-info mb-auto">
        <p class="data-info">ข้อมูลลูกค้า</p>
        <p class="title-info">หมายเลขสมาชิก (Member No.)</p>
        <p class="data-info">{{ user.member_id }}</p>
        <p class="title-info">ชื่อลูกค้า (Customer Name)</p>
        <p class="data-info">
          {{ user.first_name_th }} {{ user.last_name_th }} ({{
            user.member_level_name
          }})
        </p>
        <p class="title-info">โทรศัพท์ (Telephone)</p>
        <p class="data-info">{{ user.telephone }}</p>
        <p class="title-info">คะแนนสะสม</p>
        <p class="data-info">{{ user.center_point | numeral('0,0') }} คะแนน</p>
        <div v-if="!pointSystem">
          <p class="title-info d-flex justify-content-between">
            คะแนนสะสมสาขา
            <u class="pointer" @click="$refs.modalPoint.show(user)"
              >ดูเพิ่มเติม</u
            >
          </p>
          <p class="data-info">{{ user.point | numeral('0,0') }} คะแนน</p>
        </div>
        <p class="title-info">หมายเลขใบเสร็จ (Mim/Pos Ref No.) /-</p>
        <p class="data-info">
          <InputSelect
            :options="transactionList"
            v-model="form.transactionId"
            @onDataChange="onDataChangeTransaction"
            textField="invoice_no"
            valueField="id"
            className="mt-1"
          />
        </p>
        <div class="text-center action-desktop">
          <b-button
            variant="primary-color"
            class="w-50"
            :disabled="
              form.transactionId == 0 ||
              (transactions.length && !transactions[0].is_voidable) ||
              false
            "
            @click="$refs.modalCancelTransaction.show()"
          >
            ยกเลิกรายการ
          </b-button>
        </div>
      </div>
      <div class="text-center w-100 pb-3 action-desktop">
        <b-button variant="primary-color" to="/transaction">
          ย้อนกลับ
        </b-button>
      </div>
    </div>
    <div class="layout-right">
      <div v-for="(transaction, i) in transactions" :key="i" class="px-3">
        <div class="transaction-status">
          <div>
            <p class="text-ref-no">
              POS REF NO: {{ transaction.invoice_no }}
              <span v-if="transaction.invoiceMergePoint" class="text-danger">
                (รวมบิลกับ REF NO: {{ transaction.invoiceMergePoint }})</span
              >
              <router-link
                v-if="transaction.point_exchange_url"
                :to="transaction.point_exchange_url"
                target="_blank"
                class="ml-1"
              >
                <img
                  src="@/assets/images/icons/download-icon.jpg"
                  class="download-url pointer"
                  height="28"
                />
              </router-link>
              <font-awesome-icon
                v-if="transaction.is_fullpos == 1"
                icon="print"
                class="pointer color-primary ml-1"
                @click="printFile(transaction)"
              />
              <font-awesome-icon
                v-if="
                  transaction.is_used_coupon == 1 && transaction.is_fullpos == 1
                "
                icon="file-pdf"
                class="pointer color-primary ml-1"
                @click="exportTransaction(transaction)"
              />
            </p>
            <p class="text-time">
              {{
                $moment(transaction.created_time)
                  .add(543, 'years')
                  .format($formatDateNewFull)
              }}
              สาขา {{ transaction.branch_name }}
            </p>
          </div>
          <div class="detail">
            <p
              :class="[
                'text-status',
                transaction.user_transaction_status_id == 1
                  ? 'text-success'
                  : 'text-danger'
              ]"
            >
              สถานะการขาย: {{ transaction.status_name }}
              {{
                transaction.user_transaction_ref_id &&
                transaction.user_transaction_status_id == 1
                  ? '(Void/Returned)'
                  : ''
              }}
            </p>
            <p class="text-saleId">
              #{{ transaction.sales_no }} {{ transaction.sales_name }}
            </p>
          </div>
        </div>
        <b-row cols="5"  no-gutters class="head-total">
          <b-col >
            <div class="bg-secondary">
              <p>Total QTY</p>
              <p>(จำนวนสุทธิ)</p>
              <p class="points">{{ transaction.quantity | numeral('0,0') }}</p>
            </div>
          </b-col>
          <b-col>
            <div class="bg-secondary">
              <p>Total Amount</p>
              <p>(ราคารวม)</p>
              <p class="points">{{ transaction.price | numeral('0,0.00') }}</p>
            </div>
          </b-col>
          <b-col>
            <div class="bg-secondary">
              <p>Total Discount</p>
              <p>(ส่วนลดรวม)</p>
              <p class="points">
                {{ transaction.discount | numeral('0,0.00') }}
              </p>
            </div>
          </b-col>
          <b-col>
            <div class="bg-secondary">
              <p>Total Net Amount</p>
              <p>(ราคาสุทธิ)</p>
              <p class="points">
                {{ transaction.grand_total | numeral('0,0.00') }}
              </p>
            </div>
          </b-col>
          <b-col v-if="!pointSystem">
            <div class="bg-secondary">
              <p>Branch Point</p>
              <p>(คะแนนสะสมสาขา)</p>
              <p class="points">{{ transaction.point | numeral('0,0') }}</p>
            </div>
          </b-col>
          <b-col>
            <div class="bg-secondary">
              <p>Total Point</p>
              <p>(คะแนนสะสม)</p>
              <p class="points">
                {{ transaction.center_point | numeral('0,0') }}
              </p>
            </div>
          </b-col>
        </b-row>
        <template v-if="!isMobile">
          <div class="m-3">
            <b-row class="mx-0 text-center header-products">
              <b-col cols="1" class="header-products-item">
                <p>จำนวน</p>
                <p>Qty</p>
              </b-col>
              <b-col md="6" class="header-products-item text-left">
                <p>รายละเอียดสินค้า/โปรโมชั่น</p>
                <p>Description/Promotion</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ราคาต่อหน่วย</p>
                <p>Unit Price</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ส่วนลด</p>
                <p>Discount</p>
              </b-col>
              <b-col class="header-products-item">
                <p>ราคาสุทธิ</p>
                <p>Net</p>
              </b-col>
            </b-row>
          </div>
          <div class="product-list-container">
            <div class="item-products" v-if="transaction.product.length > 0">
              <b-row
                v-for="(product, index) in transaction.product"
                :key="index"
                class="product-panel mx-0 mb-2 h-100 py-2"
              >
                <b-col
                  cols="1"
                  class="border-product d-flex justify-content-center align-items-center"
                >
                  <p class="my-0">{{ product.quantity }}</p>
                </b-col>
                <b-col md="6" class="pointer">
                  <p>{{ product.barcode }}</p>
                  <p class="product-info">{{ product.name }}</p>
                  <p v-if="product.promotion">
                    <span>
                      โปรโมชั่น:
                      {{
                        !product.promotion || product.promotion.length == 0
                          ? '-'
                          : ''
                      }}</span
                    >
                    <span
                      v-for="(promotion, index2) in product.promotion"
                      :key="index2"
                    >
                      {{ promotion.name }} ลด {{ promotion.discount_value }},
                    </span>
                  </p>
                </b-col>
                <b-col
                  class="price d-flex justify-content-center align-items-center"
                >
                  <div>
                    <p>
                      {{ product.sap_price | numeral('0,0.00') }}
                    </p>
                    <p
                      v-if="product.sap_price != product.price"
                      class="normal-unit-price"
                    >
                      {{ product.price | numeral('0,0.00') }}
                    </p>
                  </div>
                </b-col>
                <b-col
                  class="price d-flex justify-content-center align-items-center"
                >
                  <p>
                    {{ product.total_discount | numeral('0,0.00') }}
                  </p>
                </b-col>
                <b-col
                  class="price d-flex justify-content-center align-items-center"
                >
                  <p>
                    {{ product.after_discount_price | numeral('0,0.00') }}
                  </p>
                </b-col>
              </b-row>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-if="transaction.product.length > 0">
            <b-row
              class="mx-0 my-1 p-2 bg-white product-panel"
              v-for="(product, index) in transaction.product"
              :key="index"
            >
              <b-col
                cols="2"
                class="text-center pl-0 pr-0 border-product d-flex align-items-center"
              >
                <p>{{ product.quantity }}</p>
              </b-col>

              <b-col class="px-2 pointer text-general">
                <p>{{ product.barcode }}</p>
                <p>{{ product.name }}</p>

                <p>
                  โปรโมชั่น:
                  {{ product.promotionDetail || '-' }}
                </p>
              </b-col>

              <b-col class="pr-0 pointer text-right"
                ><div>
                  <p>
                    {{ product.sap_price | numeral('0,0.00') }}
                  </p>
                  <p
                    v-if="product.sap_price != product.price"
                    class="normal-unit-price"
                  >
                    {{ product.price | numeral('0,0.00') }}
                  </p>
                </div></b-col
              >
            </b-row>
          </div>
        </template>

        <div class="text-center d-flex justify-content-between">
          <b-button
            variant="link"
            v-if="hasPromotion(i) && transaction.is_fullpos == 1"
            @click="$refs.modalEditTransactionDetail.show(transaction)"
          >
            กรอกรายละเอียด
          </b-button>
          <b-button variant="link" @click="deleteTransaction(transaction)">
            ลบรายการ
          </b-button>
        </div>
        <hr class="" />
      </div>
      <div
        class="text-center d-flex justify-content-center align-items-center p-3"
      >
        <b-button
          variant="primary-color"
          active
          :disabled="totalPage < 2 || filter.page <= 1"
          @click="changePage('prev')"
        >
          Prev
        </b-button>
        <p class="px-2">{{ filter.page }} / {{ totalPage }}</p>
        <b-button
          variant="primary-color"
          :disabled="totalPage < 2 || filter.page === totalPage"
          @click="changePage('next')"
        >
          Next
        </b-button>
      </div>
    </div>

    <div class="action-mobile">
      <b-button
        variant="primary-color"
        class="w-50"
        :disabled="
          form.transactionId == 0 ||
          (transactions.length && !transactions[0].is_voidable) ||
          false
        "
        @click="$refs.modalCancelTransaction.show()"
      >
        ยกเลิกรายการ
      </b-button>

      <b-button variant="primary-color" to="/transaction"> ย้อนกลับ </b-button>
    </div>

    <ModalPoint ref="modalPoint" :branchId="branchId" />

    <ModalCancelTransaction
      ref="modalCancelTransaction"
      :invoice_no="invoice_no"
      :fromInventoryId="form.transactionId"
      :branchId="branchId"
      :isFullPos="selectedTransaction ? selectedTransaction.is_fullpos : 0"
      @success="afterCancel"
    />
    <ModalEditTransactionDetail
      ref="modalEditTransactionDetail"
      :branchId="branchId"
      @success="id => getTransactionDetail(id)"
    />
    <ModalDeletelTransaction
      ref="modalDeletelTransaction"
      :branchId="branchId"
      @success="afterCancel"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ModalPoint from '@/components/report/pointBalance/ModalPoint';
import InputSelect from '@/components/inputs/InputSelect';
import ModalCancelTransaction from './components/ModalCancelTransaction';
import ModalEditTransactionDetail from './components/ModalEditTransactionDetail';
import ModalDeletelTransaction from './components/ModalDeletelTransaction';

export default {
  components: {
    ModalPoint,
    InputSelect,
    ModalCancelTransaction,
    ModalEditTransactionDetail,
    ModalDeletelTransaction
  },
  computed: {
    invoice_no() {
      let filtered = this.transactionList.filter(
        item => this.form.transactionId == item.id
      );
      filtered = filtered.map((item, index) => item.invoice_no);
      return filtered[0] || '';
    },
    selectedTransaction() {
      let filtered = this.transactions.find(
        item => this.form.transactionId == item.id
      );

      return filtered;
    },
    ...mapGetters(['isLoading']),
    ...mapGetters({
      mainLoading: 'getMainLoading',
      branchId: 'getBranchId',
      pointSystem: 'getPointSystem',
      isFullPos: 'getIsFullPos'
    })
  },
  data() {
    return {
      isMobile: false,
      user: {
        center_point: 0,
        created_time: '',
        credit: 0,
        first_name_th: '',
        last_name_th: '',
        member_id: '',
        member_level_id: 0,
        member_level_name: '',
        point: 0,
        telephone: '',
        user_guid: '',
        user_id: 0,
        valid_user: 1,
        birthday: '',
        consent_status: '',
        sumPoint: 0
      },
      params: {
        userGuid: '',
        transactionId: '',
        showOther: 0
      },
      form: {
        transactionId: 0
      },
      transactionList: [],
      transactions: [],
      filter: {
        page: 1
      },
      totalPage: 1
    };
  },
  async created() {
    this.params.userGuid = this.$route.params.userGuid || '';
    this.params.transactionId = this.$route.params.id;
    this.form.transactionId = this.params.transactionId;
    this.params.showOther = this.$route.params.showOther == 'true' ? 1 : 0;
    if (!this.params.transactionId)
      this.$router.push({ path: `/transaction` }).catch(() => {});

    await this.getUserDetail(this.params.userGuid);
    await this.getInvoiceList(this.params.userGuid, this.params.showOther);
    await this.getTransactionDetail(this.form.transactionId);
  },
  mounted() {
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    async afterCancel() {
      await this.getInvoiceList(this.params.userGuid, this.params.showOther);
      this.form.transactionId = 0;
      await this.getTransactionDetail(this.form.transactionId);
    },
    onResize() {
      this.isMobile = window.innerWidth < 768;
    },
    hasPromotion(index) {
      return this.transactions[index].product.some(el => el.promotion.length);
    },
    async getUserDetail(userGuid) {
      await this.axios
        .get(
          `${this.$baseUrl}/customer/user_lookup_byid/${userGuid}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.user = data.detail;
            if (this.user.birthday == '01/01/0001 00:00:00')
              this.user.birthday = null;
            else
              this.user.birthday = this.$moment(
                this.user.birthday,
                'MM/DD/YYYY HH:mm:ss'
              ).format('YYYY-MM-DD HH:mm:ss');
            this.user.sumPoint = data.detail.center_point + data.detail.point;
          }
        });
    },
    async getTransactionDetail(transactionId) {
      await this.axios
        .get(
          `${this.$baseUrl}/transaction/get_transaction_detail/${transactionId}/${this.params.userGuid}/${this.filter.page}/${this.branchId}/${this.params.showOther}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.totalPage = data.total_page;
            this.transactions = data.detail;
            await this.transactions.forEach(transaction => {
              transaction.quantity = transaction.product.reduce(
                (a, b) => a + (b.quantity || 0),
                0
              );

              transaction.price =
                transaction.discount + transaction.grand_total;

              transaction.product.forEach(product => {
                product.promotionDetail = product.promotion
                  .map(el => el.name)
                  .join(', ');
              });
            });
          } else {
            this.warningAlert(data.message);
          }
        });
    },
    async getInvoiceList(userGuid, showOther) {
      let list = [{ id: 0, invoice_no: 'ทั้งหมด' }];
      await this.axios
        .get(
          `${this.$baseUrl}/transaction/get_invoice_list/${userGuid}/${showOther}/${this.branchId}`
        )
        .then(async data => {
          if (data.result == 1) {
            this.transactionList = list.concat(data.detail);
          }
        });
    },
    async onDataChangeTransaction(val) {
      this.form.transactionId = val;
      this.getTransactionDetail(this.form.transactionId);
    },
    async deleteTransaction(transaction) {
      this.$refs.modalDeletelTransaction.show(transaction);
    },
    async changePage(type) {
      if (type == 'prev') this.filter.page -= 1;
      else if (type == 'next') this.filter.page += 1;

      await this.getTransactionDetail(0);
    },
    async exportTransaction(transaction) {
      await this.axios
        .get(`${this.$baseUrl}/Export/export_customer_point/${transaction.id}`)
        .then(async data => {
          var link = document.createElement('a');
          document.body.appendChild(link);
          link.setAttribute('type', 'hidden');
          link.href = 'data:application/pdf;base64,' + data.detail;
          link.setAttribute(
            'download',
            `transaction-${transaction.invoice_no}.pdf`
          );
          link.setAttribute('target', `_blank`);
          link.click();
        });
    },
    async printFile(transaction) {
      await this.axios
        .get(
          `${this.$baseUrl}/transaction/GetTransactionReceipt/${transaction.id}`
        )
        .then(async data => {
          this.printReceipt(data);
        });
    },
    async printReceipt(Base64String) {
      await this.axios
        .post('http://localhost:30033/api/PrintReceipt', { Base64String })
        .then(async data => {
          if (data.result !== 1) {
            this.warningAlert(data.message);
          }
        })
        .catch(err => {});
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .layout-left {
  width: 300px;
  background: #f7f7f7;
  height: calc(100vh - 46px);
  position: relative;
  font-family: Kanit-Medium !important;

  .detail-info {
    padding: 20px;

    .title-info {
      font-size: 14px;
      color: grey;
    }

    .data-info {
      color: #333;
      font-size: 16px;
      text-align: left;
      margin-bottom: 15px;
    }
  }
}

::v-deep .layout-right {
  width: calc(100% - 300px);
  height: calc(100vh - 46px);
  overflow: auto;

  .transaction-status {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    .detail {
      text-align: right;
    }
  }

  .text-ref-no {
    font-family: Kanit-Medium !important;
    font-size: 20px;
  }

  .text-time,
  .text-saleId {
    font-family: Kanit-Medium !important;
    font-size: 14px;
  }

  .text-status {
    font-family: Kanit-Medium !important;
    font-size: 18px;
  }

  .text-status.text-success {
    color: #3c763d !important;
  }

  .head-total {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;

    p {
      text-align: center;
      color: var(--primary-color) !important;
      font-size: 14px;
      font-weight: 600;
    }

    .col:first-of-type {
      padding-left: 0;
    }

    .col {
      flex: 1;
    }

    .bg-secondary {
      padding: 15px 0;
      background: var(--secondary-color) !important;
    }

    .points {
      font-size: 14px;
    }
  }

  .product-list-container {
    overflow: auto;
    height: calc(100% - 46px - 89px - 87px - 40px);
    position: relative;

    .wrap-no-product {
      width: 100%;
      height: 100%;
      display: -ms-flexbox !important;
      display: flex !important;
      -ms-flex-pack: center !important;
      justify-content: center !important;
      -ms-flex-align: center !important;
      align-items: center !important;

      .no-product {
        text-align: center;
        font-size: 20px;
        padding: 15px;
        background-color: #f7f7f7;
        border-radius: 10px;
        color: #dcdcdc;
        width: 450px;
      }
    }
  }

  .header-products {
    font-size: 14px;
    font-weight: bold;
    gap: 4px;

    .header-products-item {
      padding: 0px;
    }
  }

  .product-panel {
    background-color: rgb(244, 244, 244) !important;
  }

  .product-panel.active {
    background-color: var(--secondary-color) !important;
  }

  .item-products {
    color: #707070;
    font-size: 16px;
    border-radius: 8px;
    font-family: Kanit-Medium !important;

    svg {
      color: #000;
    }

    .price {
      font-size: 14px;
      text-align: center;
    }

    .product-info {
      font-size: 12px !important;
    }

    span {
      font-size: 12px !important;
      color: #707070 !important;
    }

    .normal-unit-price {
      text-decoration: line-through;
    }
  }
}

::v-deep .btn-link {
  border-color: transparent !important;
  background-color: transparent !important;
  text-decoration: underline !important;
  padding: 5px 0;
  color: #707070 !important;
}

.transaction-panel {
  display: flex;
}

.action-desktop {
  display: block;
}

.action-mobile {
  display: none;
}

@media (max-width: 1200px) {
  ::v-deep .layout-right {
    .head-total {
      p {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 768px) {
  .transaction-panel {
    display: block;

    .layout-left {
      width: 100%;
      height: 100%;

      .detail-info {
        width: 100%;
      }
    }

    .layout-right {
      width: 100%;
      height: 100%;

      .transaction-status {
        display: block;

        .detail {
          text-align: left;
        }
      }
    }

    .action-desktop {
      display: none;
    }

    .action-mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;
      align-items: center;
      margin-bottom: 16px;
      width: 100%;
    }
  }
}
</style>
